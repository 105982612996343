






























import Draggable from 'vuedraggable'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

import TextInput from '@/components/_uikit/controls/TextInput.vue'
import TestQuestionMixin from '@/mixins/manager/TestQuestionMixin'

@Component({
  components: {
    Draggable,
    TextInput,
    ValidationProvider,
  },
})
export default class QuestionSequenceForm extends Mixins(TestQuestionMixin) {
  // Дата выдачи д/з наступила?
  @Prop({ default: false })
  private isSpendingAt!: boolean
}
