










































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

import Select from '@/components/_uikit/controls/Select.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import { NameValueResource, TaskRateType, TestQuestionRequest } from '@/store/types'

@Component({
  components: {
    Select,
    TextInput,
    ValidationProvider,
  },
})
export default class QuestionAnswersForm extends Mixins(NotifyMixin) {
  @Prop({ required: true })
  private form!: TestQuestionRequest

  @Prop({ required: true })
  private formIndex!: number

  @Prop({ default: () => ([]) })
  private rateTypes!: NameValueResource[]

  // Дата выдачи д/з наступила?
  @Prop({ default: false })
  private isSpendingAt!: boolean

  private get isRateNoRight () {
    return this.form.rateType === TaskRateType.NO_RIGHT
  }

  private get isRateNoErrors () {
    return this.form.rateType === TaskRateType.NO_ERRORS
  }

  private get isRateManyErrors () {
    return this.form.rateType === TaskRateType.MANY_ERRORS
  }

  private get isRateOneError () {
    return this.form.rateType === TaskRateType.ONE_ERROR
  }

  private get isRateEveryRight () {
    return this.form.rateType === TaskRateType.EVERY_RIGHT
  }
}
