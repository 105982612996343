import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { TestAnswerRequest, TestQuestionRequest } from '@/store/types'
import { GET_DEFAULT_TEST_ANSWER } from '@/components/forms/exercise/constants'

@Component
export default class TestQuestionMixin extends Vue {
  @Prop({ required: true })
  protected form!: TestQuestionRequest

  @Prop({ required: true })
  protected formIndex!: number

  protected maxAnswersCount = 8

  protected handleAnswersDrag () {
    this.form.answers.forEach((answer: TestAnswerRequest, index) => {
      answer.position = index + 1
    })
  }

  protected handleAddAnswer(type?: string) {
    this.form.answers.push({
      ...GET_DEFAULT_TEST_ANSWER(),
      position: this.form.answers.length + 1,
    })

    setTimeout(() => {
      const { answers } = this.$refs as any
      const lastAnswerInput = answers[answers.length - 1].$el.querySelector(type === 'ratio' ? 'textarea' : 'input')

      if (lastAnswerInput) {
        lastAnswerInput.focus()
      }
    }, 100)
  }
}
