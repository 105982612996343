















































































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Prop, Ref } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'

import Confirmation from '@/components/modals/Confirmation.vue'
import ExerciseTaskCommonForm from '@/components/forms/exercise/ExerciseTaskCommonForm.vue'
import ExerciseTaskPracticeQuestionForm from '@/components/forms/exercise/ExerciseTaskPracticeQuestionForm.vue'
import ExerciseTaskTestQuestionForm from '@/components/forms/exercise/ExerciseTaskTestQuestionForm.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import ManagerExercisesModule from '@/store/modules/manager/exercises'
import {
  MediaResource,
  NameValueResource,
  PracticeQuestionRequest,
  SpeakingQuestionRequest,
  SpeakingQuestionType,
  TaskCreativeResource,
  TaskPracticeResource,
  TaskQuestionType,
  TaskStoreRequest,
  TaskTestResource,
  TaskType,
  TestQuestionRequest,
} from '@/store/types'
import ExerciseTaskSpeakingQuestionForm from '@/components/forms/exercise/ExerciseTaskSpeakingQuestionForm.vue'

@Component({
  components: {
    Confirmation,
    ExerciseTaskCommonForm,
    ExerciseTaskPracticeQuestionForm,
    ExerciseTaskSpeakingQuestionForm,
    ExerciseTaskTestQuestionForm,
    ValidationObserver,
  },
})
export default class ExerciseTaskForm extends Mixins(NotifyMixin) {
  @Ref() confirm!: Confirmation

  @Prop({ required: true })
  private subject!: NameValueResource

  @Prop({ required: true })
  private form!: TaskStoreRequest

  @Prop({ required: true })
  private task!: TaskCreativeResource|TaskPracticeResource|TaskTestResource

  @Prop({ required: true })
  private autoCheck!: boolean

  @Prop({ default: () => ([]) })
  private materials!: MediaResource[]

  @Prop({ default: () => ([]) })
  private records!: MediaResource[]

  // Дата выдачи д/з наступила?
  @Prop({ default: false })
  private isSpendingAt!: boolean

  // Индекс активного таба (для пробников)
  @Prop({ default: 0 })
  private selectedTaskIndex!: number

  private disabledType = false

  private get isCreative () {
    return this.form.type === TaskType.CREATIVE
  }

  private get isTest () {
    return this.form.type === TaskType.TEST
  }

  private get isPractice () {
    return this.form.type === TaskType.PRACTICE
  }

  private get isSpeaking() {
    return this.form.type === TaskType.SPEAKING
  }

  // Массив uuid вопросов теста для возможности связывания вопросов
  private get uuidListQuestions() {
    if (this.isTest) {
      return this.form.test.questions.map(question => question.uuid)
    }
    return []
  }

  private get isIncorrect() {
    return (this.isTest && !this.form.test.questions.every(question => {
      if (question.type === 'single' || question.type === 'multiple') {
        return question.answers.some(answer => answer.isCorrect)
      }
      return true
    }))
  }

  private get visibleQuestions() {
    const totalQuestions = this.form.test.questions.length
    const indexes: number[] = [this.visibleIndexes]

    if (!this.visibleIndexes) {
      if (totalQuestions >= 3) {
        indexes.push(this.visibleIndexes + 1, this.visibleIndexes + 2)
        return indexes
      }
      if (totalQuestions === 2) {
        indexes.push(this.visibleIndexes + 1)
        return indexes
      }
    } else if (this.visibleIndexes === totalQuestions - 1) {
      if (totalQuestions >= 3) {
        indexes.unshift(this.visibleIndexes - 2, this.visibleIndexes - 1)
        return indexes
      }
      if (totalQuestions === 2) {
        indexes.unshift(this.visibleIndexes - 1)
        return indexes
      }
    } else {
      if (this.visibleIndexes - 2 >= 0 && this.visibleIndexes + 2 <= totalQuestions - 1) {
        indexes.unshift(this.visibleIndexes - 2, this.visibleIndexes - 1)
        indexes.push(this.visibleIndexes + 1, this.visibleIndexes + 2)
        return indexes
      } else if (this.visibleIndexes - 1 >= 0 && this.visibleIndexes + 2 <= totalQuestions - 1) {
        indexes.unshift(this.visibleIndexes - 1)
        indexes.push(this.visibleIndexes + 1, this.visibleIndexes + 2)
        return indexes
      } else {
        indexes.unshift(this.visibleIndexes - 2, this.visibleIndexes - 1)
        indexes.push(this.visibleIndexes + 1)
        return indexes
      }
    }
    return indexes
  }

  private visibleIndexes = 0

  private questionsId = this.form.test.questions.map((question, index) => question.id || index)

  private mounted () {
    this.$bus.$on(`save-exercise-container-${this.selectedTaskIndex}`, this.handleSubmit)
    if (this.task && this.task.id) {
      this.disabledType = true
    }
  }

  private destroyed() {
    this.$bus.$off(`save-exercise-container-${this.selectedTaskIndex}`, this.handleSubmit as any)
  }

  private handleAddPracticeQuestion (index: number, form: PracticeQuestionRequest) {
    this.form.practice.questions.splice(index, 0, form)
    this.questionsId.splice(index, 0, Math.random())
  }

  private handleDeletePracticeQuestion (index: number) {
    this.form.practice.questions.splice(index, 1)
    this.questionsId.splice(index, 1)
  }

  private handleMoveUpPractice(index: number) {
    const question = this.form.practice.questions[index]
    this.form.practice.questions.splice(index, 1)
    this.form.practice.questions.splice(index - 1, 0, question)
  }

  private handleMoveDownPractice(index: number) {
    const question = this.form.practice.questions[index]
    this.form.practice.questions.splice(index, 1)
    this.form.practice.questions.splice(index + 1, 0, question)
  }

  private handleAddSpeakingQuestion (index: number, form: SpeakingQuestionRequest) {
    this.form.speaking.questions.splice(index, 0, form)
    this.questionsId.splice(index, 0, Math.random())
  }

  private handleDeleteSpeakingQuestion (index: number) {
    this.form.speaking.questions.splice(index, 1)
    this.questionsId.splice(index, 1)
  }

  private handleAddTestQuestion (index: number, form: TestQuestionRequest) {
    this.form.test.questions.splice(index, 0, form)
  }

  private handleMoveUpTest(index: number) {
    const question = this.form.test.questions[index]
    this.form.test.questions.splice(index, 1)
    this.form.test.questions.splice(index - 1, 0, question)
  }

  private handleMoveDownTest(index: number) {
    const question = this.form.test.questions[index]
    this.form.test.questions.splice(index, 1)
    this.form.test.questions.splice(index + 1, 0, question)
  }

  private handleDeleteTestQuestion (index: number) {
    this.form.test.questions.splice(index, 1)
  }

  private handleCameIntoView (index: number) {
    this.visibleIndexes = index
  }

  private get test() {
    return !this.isTest && this.autoCheck && !this.form.answerMediaIds.length
  }

  private get questionListTypeText() {
    const question: Record<string, number> = {}
    this.form.test.questions.forEach((q: TestQuestionRequest, index: number) => {
      if (q.type === TaskQuestionType.TEXT)
        question[q.uuid] = index + 1
    })
    return question
  }

  // Валидность текстовых вопрос на содержание символов одной раскладки
  private get isInvalidKeyboardLayout() {
    const questions: ({ answer: number, question: number })[] = []
    const cyrillic = new RegExp('[а-я]+', 'i')
    const latin = new RegExp('[a-z]+', 'i')
    this.form.test.questions.forEach((q, index) => {
      if (this.questionListTypeText[q.uuid] && q.answers.length) {
        q.answers.forEach((answer, idx) => {
          if (cyrillic.test(answer.text) && latin.test(answer.text))
            questions.push({ answer: idx + 1, question: index + 1 })
        })
      }
    })
    return questions
  }

  @Debounce(300)
  @Bind
  private handleSubmit() {
    const form: any = this.$refs.form

    form.validate()
      .then((result: boolean) => {
        if (result) {

          this.form.speaking.questions = this.form.speaking.questions.map(question => {
            if (question.type !== SpeakingQuestionType.AUDIO_SINGLE) {
              delete question.text
            }
            return question
          })

          if (!this.isTest && this.autoCheck && !this.form.answerMediaIds.length) {
            this.notifyError('Добавьте ключ проверки')
            return
          }

          if (this.isTest && this.isInvalidKeyboardLayout.length) {
            this.confirm.open(
              'Ошибка при вводе ответа',
              `Кажется, вы использовали смешанную раскладку клавиатуры. Ответ должен быть написан символами одного языка (русский или английский). Пример ошибки: <span class="secondary--text">SМИТАP</span>. Проверьте задания и сохраните их повторно: <br /> ${this.isInvalidKeyboardLayout.map(q => `<span class="primary--text">вопрос: ${q.question}, вариант: ${q.answer}</span><br />`).join('')}`,
              {
                buttonCancelText: 'Исправить',
                buttonConfirmText: 'Пропустить',
              },
            )
              .then(() => {
                if (this.isTest && this.form.test.questions.some(item => item.type === 'ratio' && !item.mediaIds.length && item.answers.every(answer => !answer.text) && item.sequences.every(sequence => !sequence.text))) {
                  this.notifyError('В одном из вопросов на установление последовательности не заполнены варианты ответов или отсуствует аудио-вопрос')
                  return
                }

                if (this.isSpeaking && this.form.speaking.questions.some(item => item.type === 'audio_single' ? false : item.questions.length < 2)) {
                  this.notifyError('В типе вопроса "Очередь ответов" должно быть минимум 2 подвопроса')
                  return
                }

                if (this.isPractice && this.form.practice.questions.some(item => !item.position) ) {
                  this.notifyError('На одном из заданий не проставлен номер')
                  return
                }
                if (this.isPractice && this.form.practice.questions.some(item => item.position && item.position <= 0) ) {
                  this.notifyError('Во второй части есть некорректный номер задания')
                  return
                }

                if (this.isIncorrect) {
                  this.notifyError('В одном и более вопросах не указаны верные ответы')
                  return
                }

                if (this.$route.name === 'manager.bank.exercises.item.edit' || !this.isSpendingAt) {
                  this.saveChangeExercise(form)
                  return
                }

                this.confirm.open(
                  'Сохранение изменений',
                  'Вы уверены, что хотите сохранить внесенные изменения в домашнее задание? После сохранения изменения будут доступны всем пользователям с доступом к заданию.',
                  {
                    buttonConfirmText: 'Сохранить',
                    skin: 'secondary',
                  },
                )
                  .then(() => {
                    // Сохраняем основу домашнего задания
                    this.saveChangeExercise(form)
                  })
                  .catch(() => {return})
              })
              .catch(() => {return})
            return
          }

          if (this.isTest && this.form.test.questions.some(item => item.type === 'ratio' && !item.mediaIds.length && item.answers.every(answer => !answer.text) && item.sequences.every(sequence => !sequence.text))) {
            this.notifyError('В одном из вопросов на установление последовательности не заполнены варианты ответов или отсуствует аудио-вопрос')
            return
          }

          if (this.isSpeaking && this.form.speaking.questions.some(item => item.type === 'audio_single' ? false : item.questions.length < 2)) {
            this.notifyError('В типе вопроса "Очередь ответов" должно быть минимум 2 подвопроса')
            return
          }

          if (this.isPractice && this.form.practice.questions.some(item => !item.position) ) {
            this.notifyError('На одном из заданий не проставлен номер')
            return
          }
          if (this.isPractice && this.form.practice.questions.some(item => item.position && item.position <= 0) ) {
            this.notifyError('Во второй части есть некорректный номер задания')
            return
          }

          if (this.isIncorrect) {
            this.notifyError('В одном и более вопросах не указаны верные ответы')
            return
          }

          if (this.$route.name === 'manager.bank.exercises.item.edit' || !this.isSpendingAt) {
            this.saveChangeExercise(form)
            return
          }

          this.confirm.open(
            'Сохранение изменений',
            'Вы уверены, что хотите сохранить внесенные изменения в домашнее задание? После сохранения изменения будут доступны всем пользователям с доступом к заданию.',
            {
              buttonConfirmText: 'Сохранить',
              skin: 'secondary',
            },
          )
            .then(() => {
              // Сохраняем основу домашнего задания
              this.saveChangeExercise(form)
            })
            .catch(() => {return})
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  // Сохранение таски дз
  private saveChangeExercise(form: any) {
    ManagerExercisesModule.saveTask({
      ...this.form,
      practice: {
        questions: this.form.practice.questions.map((question, index) => ({
          ...question,
          position: index + 1,
        })),
      },
      speaking: {
        questions: this.form.speaking.questions.map(question => ({
          ...question,
          questions: question.questions.map((subQuestion, index) => ({
            ...subQuestion,
            position: index + 1,
          })),
        })),
      },
      test: {
        questions: this.form.test.questions.map((question, index) => {
          if (question.type === TaskQuestionType.TEXT)
            question.answers.splice(question.answers.length - 1, 1)
          return {
            ...question,
            answers: question.type === TaskQuestionType.NO_ANSWER? [] : question.answers.map((answer, idx) => ({ ...answer, position: idx + 1 })),
            position: index + 1,
            sequences: question.sequences.map((sequence, idx) => ({ ...sequence, position: idx + 1 })),
          }
        }),
      },
    })
      .then(() => {
        this.$bus.$emit('manual-save-exercise-base', { autoCheck: false, confirm: true })
        this.disabledType = true
        this.notifySuccess('Задание сохранено')
        requestAnimationFrame(() => (form.reset()))
      })
      .catch(this.notifyError)
  }
}
