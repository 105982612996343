import { uniqBy } from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { MediaResource } from '@/store/types'

@Component
export default class ExerciseMaterialsMixin extends Vue {
  @Prop({ default: () => ([]) })
  private materials!: MediaResource[]

  protected getMaterial (id: number) {
    const uniqMaterials = uniqBy(this.materials, 'id')
    return id ? uniqMaterials.find((material: MediaResource) => material.id === id) : null
  }

  protected getMaterials (ids: number[]) {
    const uniqMaterials = uniqBy(this.materials, 'id')
    return uniqMaterials.filter((material: MediaResource) => ids.indexOf(material.id) >= 0) || []
  }
}
