




































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

import AttachmentsWrapper from '@/components/_uikit/AttachmentsWrapper.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import TiptapEditor from '@/components/_uikit/editor/TiptapEditor.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import { MediaResource, NameValueResource, TestQuestionRequest } from '@/store/types'

@Component({
  components: {
    AttachmentsWrapper,
    Select,
    TextInput,
    TiptapEditor,
    ValidationProvider,
  },
})
export default class QuestionAdditionalForm extends Mixins(NotifyMixin) {
  @Prop({ required: true })
  private subject!: NameValueResource

  @Prop({ required: true })
  private form!: TestQuestionRequest

  @Prop({ required: true })
  private formIndex!: number

  @Prop({ default: null })
  private file!: MediaResource

  @Prop({ default: [] })
  private files!: MediaResource[]

  @Prop({ required: false })
  private showAdditionalFile!: boolean

  @Prop({ required: false })
  private showAdditionalHintAnswer!: boolean

  @Prop({ required: false })
  private showAdditionalHintQuestion!: boolean

  @Prop({ required: false })
  private showRelatedQuestions!: boolean

  // Массив uuid вопросов теста для возможности связывания вопросов
  @Prop({ default: () => ([]) })
  private uuidListQuestions!: string[]

  private get uploadParams () {
    return [{
      name: 'folder',
      value: `${this.subject.name}/Домашняя работа/Материалы`,
    }]
  }

  private get relatedListQuestions() {
    const list: NameValueResource[] = []
    this.uuidListQuestions.forEach((question, index) => {
      if (question !== this.form.uuid) {
        list.push({ name: (index + 1).toString(), value: question })
      }
    })
    return list
  }

  private handleDeleteFile () {
    this.form.mediaIds = []
  }

  private handleDeleteAdditionalFile () {
    this.handleDeleteFile()
    this.$emit('update:showAdditionalFile', false)
  }

  private handleDeleteHintAnswer () {
    this.form.hintAnswer = ''
    this.$emit('update:showAdditionalHintAnswer', false)
  }

  private handleDeleteHintQuestion () {
    this.form.hintQuestion = ''
    this.$emit('update:showAdditionalHintQuestion', false)
  }

  private handleDeleteRelatedQuestion() {
    this.form.relatedUuid = ''
    this.$emit('update:showRelatedQuestions', false)
  }
}
