var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ExerciseTaskQuestionWrapper',{attrs:{"form":_vm.form,"index":_vm.index,"type":"practice","is-last":_vm.isLast},on:{"add-form":function (index, form) { return _vm.$emit('add-form', index, form); },"delete-form":function (index) { return _vm.$emit('delete-form', index); },"submit":function($event){return _vm.$emit('submit')},"delete":function($event){return _vm.$emit('delete')},"moveUp":function (index) { return _vm.$emit('moveUp', index); },"moveDown":function (index) { return _vm.$emit('moveDown', index); }}},[_c('VCardText',{staticClass:"question-header"},[_c('ValidationProvider',{staticClass:"question-header__number space-r-6",attrs:{"name":("question-number-" + _vm.index + "}"),"rules":"required|numeric","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"№","type":"number","max":"999","invalid":errors.length > 0},model:{value:(_vm.form.displayedPosition),callback:function ($$v) {_vm.$set(_vm.form, "displayedPosition", _vm._n($$v))},expression:"form.displayedPosition"}})]}}])}),(!_vm.isSpendingAt)?_c('div',{staticClass:"question-header__actions"},[(_vm.$vuetify.breakpoint.width >= 600)?[_c('ButtonIconAction',{attrs:{"color":"teal","small":false,"icon":"$plus"},on:{"click":_vm.handleAddForm}}),_c('ButtonIconAction',{attrs:{"color":"teal","small":false,"icon":"$moveUp","disabled":_vm.index === 0},on:{"click":function($event){return _vm.$emit('moveUp', _vm.index)}}}),_c('ButtonIconAction',{attrs:{"color":"teal","small":false,"icon":"$moveDown","disabled":_vm.isLast},on:{"click":function($event){return _vm.$emit('moveDown', _vm.index)}}}),_c('ButtonIconAction',{attrs:{"color":"teal","small":false,"icon":"$duplicate"},on:{"click":_vm.handleCopyForm}}),(_vm.index > 0 || (_vm.index === 0 && !_vm.isLast))?[_c('ButtonIconAction',{attrs:{"color":"teal","small":false,"icon":"$trash"},on:{"click":function($event){return _vm.$emit('delete-form', _vm.index)}}})]:_vm._e()]:[_c('Parameters',{attrs:{"title":"Действия","iconName":"$arrowDown","list":_vm.actions},on:{"select-option":_vm.handleMenuItemActionClick}})]],2):_vm._e(),_c('div',{staticClass:"question-header__field"},[_c('div',{staticClass:"text--darken-4 teal--text mb-2"},[_vm._v("Текст вопроса")]),_c('ValidationProvider',{attrs:{"name":("question-text-" + _vm.index + "}"),"rules":"requiredHTML","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TiptapEditor',{class:{ invalid: errors.length > 0 },attrs:{"type":"MENTOR","placeholder":"Введите вопрос...","showButtonList":_vm.buttonList,"name":("question-" + _vm.index + "}"),"showPreviewSwitcher":false,"borderTopNone":true,"invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.question),callback:function ($$v) {_vm.$set(_vm.form, "question", $$v)},expression:"form.question"}}),(errors[0])?_c('div',{staticClass:"question-header__field-error red--text mt-2"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('VCardText',{staticClass:"question-grid"},[_c('div',{staticClass:"question-grid__cell_full"},[_c('TextAreaInput',{attrs:{"name":("question-text-" + _vm.index + "}"),"label":"Текст задания","placeholder":"Текст задания"},model:{value:(_vm.form.hintQuestion),callback:function ($$v) {_vm.$set(_vm.form, "hintQuestion", $$v)},expression:"form.hintQuestion"}})],1),_c('AttachmentsWrapper',{staticClass:"question-grid__cell_full",attrs:{"accept":"image/*","additionalUploadParams":_vm.uploadParams,"files":_vm.getMaterials(_vm.form.mediaIds),"value":_vm.form.mediaIds,"hint":"Изображение во второй части","withoutHeader":true},on:{"update:value":function($event){return _vm.$set(_vm.form, "mediaIds", $event)},"add-files":function (response) { return _vm.$bus.$emit('addMaterials', [response]); }}})],1),_c('VDivider',{staticClass:"my-0"}),_c('VCardText',{staticClass:"question-grid"},[_c('ValidationProvider',{staticClass:"question-grid__cell_full",attrs:{"name":("rightAnswer-" + _vm.index + "}"),"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextAreaInput',{attrs:{"name":("rightAnswer-" + _vm.index + "}"),"label":"Правильный ответ","placeholder":"Введите ответ","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.rightAnswer),callback:function ($$v) {_vm.$set(_vm.form, "rightAnswer", $$v)},expression:"form.rightAnswer"}})]}}])}),_c('ValidationProvider',{staticClass:"question-grid__cell_2",attrs:{"name":("maxPoints-" + _vm.index + "}"),"rules":"required|min_value:1","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"name":("maxPoints-" + _vm.index + "}"),"label":"Макс балл","type":"number","min":"1","dense":"","placeholder":"Введите баллы","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.maxPoints),callback:function ($$v) {_vm.$set(_vm.form, "maxPoints", $$v)},expression:"form.maxPoints"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }