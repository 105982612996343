


















import { Component, Mixins } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

// components
import TiptapEditor from '@/components/_uikit/editor/TiptapEditor.vue'
// mixins
import SpeakingQuestionMixin from '@/mixins/manager/SpeakingQuestionMixin'

@Component({
  components: {
    //TextAreaInput,
    TiptapEditor,
    ValidationProvider,
  },
})
export default class QuestionAudioSingleForm extends Mixins(SpeakingQuestionMixin) {}
