var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"answers"},[_c('ValidationProvider',{staticClass:"answers__type",attrs:{"name":("rate-type-" + _vm.formIndex),"rules":"requiredSelect","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{attrs:{"options":_vm.rateTypes,"placeholder":"Выберите тип","label":"Проверка","hide-details":"","dense":"","rounded":"","outlined":"","disabled":_vm.isSpendingAt,"invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.rateType),callback:function ($$v) {_vm.$set(_vm.form, "rateType", $$v)},expression:"form.rateType"}})]}}])}),_c('div',{staticClass:"answers__balls"},[(!_vm.isRateNoRight)?[_c('ValidationProvider',{attrs:{"name":("points-" + _vm.formIndex),"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"name":("points-" + _vm.formIndex),"type":"number","min":"1","placeholder":"Балл","label":_vm.isRateEveryRight || _vm.isRateNoErrors ? 'Балл' : 'Макс балл',"hide-details":"","dense":"","disabled":_vm.isSpendingAt,"invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.points),callback:function ($$v) {_vm.$set(_vm.form, "points", $$v)},expression:"form.points"}})]}}],null,false,4225933460)})]:_vm._e(),(_vm.isRateManyErrors)?[_c('ValidationProvider',{attrs:{"name":("points-many-errors-one-error-" + _vm.formIndex),"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"name":("points-many-errors-" + _vm.formIndex),"type":"number","min":"0","placeholder":"Балл","label":"За 1 ошибку","hide-details":"","dense":"","disabled":_vm.isSpendingAt,"invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.oneErrorPoints),callback:function ($$v) {_vm.$set(_vm.form, "oneErrorPoints", $$v)},expression:"form.oneErrorPoints"}})]}}],null,false,3954700364)}),_c('ValidationProvider',{attrs:{"name":("points-many-errors-many-errors-" + _vm.formIndex),"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"name":("points-many-errors-" + _vm.formIndex),"type":"number","min":"0","placeholder":"Балл","label":"За 2-3 ошибки","hide-details":"","dense":"","disabled":_vm.isSpendingAt,"invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.manyErrorPoints),callback:function ($$v) {_vm.$set(_vm.form, "manyErrorPoints", $$v)},expression:"form.manyErrorPoints"}})]}}],null,false,2433336373)})]:(_vm.isRateOneError)?[_c('ValidationProvider',{attrs:{"name":("points-rate-one-" + _vm.formIndex),"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"name":("points-rate-one-" + _vm.formIndex),"type":"number","min":"0","placeholder":"Балл","label":"За 1 ошибку","hide-details":"","dense":"","disabled":_vm.isSpendingAt,"invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.oneErrorPoints),callback:function ($$v) {_vm.$set(_vm.form, "oneErrorPoints", $$v)},expression:"form.oneErrorPoints"}})]}}])})]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }