










































import { Editor, EditorContent } from '@tiptap/vue-2'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
import Text from '@tiptap/extension-text'
import Link from '@tiptap/extension-link'
import { Component, Prop, Vue } from 'vue-property-decorator'

import WysiwygLinkModal from '@/components/_uikit/controls/wysiwyg/WysiwygLinkModal.vue'

@Component({
  components: {
    EditorContent,
    WysiwygLinkModal,
  },
})
export default class TextWysiwyg extends Vue {
  @Prop({ default: '' })
  private value!: string

  @Prop({ default: '' })
  private label!: string

  @Prop({ default: 'Введите текст' })
  private placeholder!: string

  @Prop({ default: false })
  private invalid!: boolean

  @Prop({ default: '' })
  private error!: string

  private showLinkModal = false
  private editor: any = null

  private mounted () {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        Document,
        Link.configure({
          HTMLAttributes: {
            target: '_blank',
          },
          openOnClick: false,
        }),
        Paragraph,
        Placeholder.configure({
          placeholder: this.placeholder,
        }),
        Text,
      ],
    })

    this.editor.on('update', this.handleInput)
  }

  private handleAddLink (form: { url: string, blank: boolean }) {
    this.editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: form.url, target: form.blank ? '_blank' : '' })
      .run()
  }

  private handleInput () {
    this.$emit('input', this.editor.getHTML())
  }
}
