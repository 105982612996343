


























































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import { omit } from 'lodash'

// components
import AttachmentsWrapper from '@/components/_uikit/AttachmentsWrapper.vue'
import ExerciseTaskQuestionWrapper from '@/components/forms/exercise/ExerciseTaskQuestionWrapper.vue'
import QuestionAudioQueueForm from '@/components/forms/exercise/question/QuestionAudioQueueForm.vue'
import QuestionAudioSingleForm from '@/components/forms/exercise/question/QuestionAudioSingleForm.vue'
import QuestionTextQueueForm from '@/components/forms/exercise/question/QuestionTextQueueForm.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TextAreaInput from '@/components/_uikit/controls/TextAreaInput.vue'
// mixins
import ExerciseMaterialsMixin from '@/mixins/manager/ExerciseMaterialsMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import { NameValueResource, SpeakingSubQuestionRequest, SpeakingQuestionRequest, SpeakingQuestionType, MediaResource } from '@/store/types'
import DictionaryModule from '@/store/modules/dictionary'
// constants
import { GET_DEFAULT_SPEAKING_SUB_QUESTION, GET_DEFAULT_SPEAKING_QUESTION_FORM } from '@/components/forms/exercise/constants'

@Component({
  components: {
    AttachmentsWrapper,
    ExerciseTaskQuestionWrapper,
    QuestionAudioQueueForm,
    QuestionAudioSingleForm,
    QuestionTextQueueForm,
    Select,
    TextAreaInput,
    ValidationProvider,
  },
})
export default class ExerciseTaskSpeakingQuestionForm extends Mixins(ExerciseMaterialsMixin, NotifyMixin) {
  @Prop({ required: true })
  private subject!: NameValueResource

  @Prop({ required: true })
  private form!: SpeakingQuestionRequest

  @Prop({ required: true })
  private index!: number

  @Prop({ default: false })
  private isLast!: boolean

  // Дата выдачи д/з наступила?
  @Prop({ default: false })
  private isSpendingAt!: boolean

  @Prop({ default: () => ([]) })
  private records!: MediaResource[]

  private get questionTypes() {
    return DictionaryModule.taskSpeakingQuestionTypes
  }

  private get isQuestionAudioSingle() {
    return this.form.type === SpeakingQuestionType.AUDIO_SINGLE
  }

  private get isQuestionTextQueue() {
    return this.form.type === SpeakingQuestionType.TEXT_QUEUE
  }

  private get isQuestionAudioQueue() {
    return this.form.type === SpeakingQuestionType.AUDIO_QUEUE
  }

  private get uploadParams () {
    return [{
      name: 'folder',
      value: `${this.subject.name}/Домашняя работа/Материалы`,
    }]
  }

  private handleAddForm() {
    this.$emit('add-form', this.index + 1, GET_DEFAULT_SPEAKING_QUESTION_FORM())
  }

  private handleCopyForm() {
    this.$emit('add-form', this.index + 1, {
      ...omit(this.form, 'id'),
      questions: (this.form as SpeakingQuestionRequest).questions.map((answer: SpeakingSubQuestionRequest) => omit(answer, 'id')),
    })
  }

  private handleChangeQuestionType(type: SpeakingQuestionType) {
    if (type !== 'text_queue')
      this.form.questions = []
    else
      this.form.questions = [GET_DEFAULT_SPEAKING_SUB_QUESTION(), GET_DEFAULT_SPEAKING_SUB_QUESTION(2)]
  }
}
