import { v4 as uuid } from 'uuid'

import {
  PracticeQuestionRequest, SpeakingSubQuestionRequest, SpeakingQuestionRequest, SpeakingQuestionType,
  TaskQuestionType,
  TaskRateType,
  TaskStoreRequest,
  TaskType,
  TestAnswerRequest,
  TestQuestionRequest,
} from '@/store/types'

export const GET_DEFAULT_TEST_ANSWER = (): TestAnswerRequest => {
  return {
    isCorrect: false,
    position: 1,
    text: '',
  }
}

export const GET_DEFAULT_SPEAKING_SUB_QUESTION = (position = 1): SpeakingSubQuestionRequest => {
  return {
    mediaId: undefined,
    position,
    required: true,
    text: undefined,
  }
}

export const GET_DEFAULT_TEST_QUESTION_FORM = (): TestQuestionRequest => {
  return {
    answers: [GET_DEFAULT_TEST_ANSWER()],
    displayType: 'column',
    hintAnswer: '',
    hintQuestion: '',
    manyErrorPoints: 0,
    mediaIds: [],
    nameAnswerTable: 'ПРОЦЕССЫ (ЯВЛЕНИЯ, СОБЫТИЯ)',
    nameSequenceTable: '',
    oneErrorPoints: 0,
    points: 1,
    position: 1,
    question: '',
    rateType: TaskRateType.NO_ERRORS,
    relatedUuid: '',
    sequences: [],
    type: TaskQuestionType.SINGLE,
    uuid: uuid(),
  }
}

export const GET_DEFAULT_PRACTICE_QUESTION_FORM = (): PracticeQuestionRequest => {
  return {
    displayedPosition: 20,
    maxPoints: 10,
    mediaIds: [],
    position: 1,
    question: '',
    rightAnswer: '',
    uuid: uuid(),
  }
}

export const GET_DEFAULT_SPEAKING_QUESTION_FORM = (): SpeakingQuestionRequest => {
  return {
    mediaIds: [],
    position: 1,
    question: '',
    questions: [],
    text: '',
    type: SpeakingQuestionType.AUDIO_SINGLE,
  }
}

export const GET_DEFAULT_TASK_FORM = (): TaskStoreRequest => {
  return {
    annotation: '',
    answerMediaIds: [],
    description: '',
    exerciseUuid: '',
    managerAnnotation: '',
    masterAnnotation: '',
    maxPoints: 10,
    mediaIds: [],
    practice: {
      questions: [GET_DEFAULT_PRACTICE_QUESTION_FORM()],
    },
    speaking: {
      questions: [GET_DEFAULT_SPEAKING_QUESTION_FORM()],
    },
    test: {
      questions: [GET_DEFAULT_TEST_QUESTION_FORM()],
    },
    title: '',
    type: TaskType.CREATIVE,
    uuid: '',
    verificationHours: 0,
  }
}
