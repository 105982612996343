

































































































































import { Component, Mixins, Prop, Ref } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import { omit } from 'lodash'
import { v4 as uuid } from 'uuid'

import ExerciseTaskQuestionWrapper from '@/components/forms/exercise/ExerciseTaskQuestionWrapper.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TextAreaInput from '@/components/_uikit/controls/TextAreaInput.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import QuestionAdditionalForm from '@/components/forms/exercise/question/QuestionAdditionalForm.vue'
import QuestionAnswersForm from '@/components/forms/exercise/question/QuestionAnswersForm.vue'
import QuestionMultipleForm from '@/components/forms/exercise/question/QuestionMultipleForm.vue'
import QuestionRatioForm from '@/components/forms/exercise/question/QuestionRatioForm.vue'
import QuestionSequenceForm from '@/components/forms/exercise/question/QuestionSequenceForm.vue'
import QuestionSingleForm from '@/components/forms/exercise/question/QuestionSingleForm.vue'
import QuestionTextForm from '@/components/forms/exercise/question/QuestionTextForm.vue'
import TiptapEditor from '@/components/_uikit/editor/TiptapEditor.vue'
import { GET_DEFAULT_TEST_ANSWER, GET_DEFAULT_TEST_QUESTION_FORM } from '@/components/forms/exercise/constants'
import Parameters from '@/components/_uikit/Parameters.vue'
import ExerciseMaterialsMixin from '@/mixins/manager/ExerciseMaterialsMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import DictionaryModule from '@/store/modules/dictionary'
import { NameValueResource, TaskQuestionType, TaskRateType, TestAnswerRequest, TestQuestionRequest, TestSequenceRequest } from '@/store/types'

enum MenuActions {
  ADD_FILE = 'addFile',
  ADD_HINT_ANSWER = 'addHintAnswer',
  ADD_HINT_QUESTION = 'addHintQuestion',
  ADD_RELATED_QUESTIONS = 'addRelatedQuestions',
}

enum MenuTestActions {
  ADD = 'add',
  COPY = 'copy',
  DELETE = 'delete',
  DOWN = 'down',
  UP = 'up',
}

@Component({
  components: {
    ExerciseTaskQuestionWrapper,
    Parameters,
    QuestionAdditionalForm,
    QuestionAnswersForm,
    QuestionMultipleForm,
    QuestionRatioForm,
    QuestionSequenceForm,
    QuestionSingleForm,
    QuestionTextForm,
    Select,
    TextAreaInput,
    TextInput,
    TiptapEditor,
    ValidationProvider,
  },
})
export default class ExerciseTaskTestQuestionForm extends Mixins(ExerciseMaterialsMixin, NotifyMixin) {
  @Ref() formWrapper!: ExerciseTaskQuestionWrapper

  @Prop({ required: true })
  private subject!: NameValueResource

  @Prop({ required: true })
  private form!: TestQuestionRequest

  @Prop({ required: true })
  private index!: number

  @Prop({ default: false })
  private isLast!: boolean

  // Дата выдачи д/з наступила?
  @Prop({ default: false })
  private isSpendingAt!: boolean

  // Массив uuid вопросов теста для возможности связывания вопросов
  @Prop({ default: () => ([]) })
  private uuidListQuestions!: string[]

  @Prop({ default: true })
  private isOutOfSight!: boolean

  private showAdditionalFile = false
  private showAdditionalHintAnswer = false
  private showAdditionalHintQuestion = false
  private showRelatedQuestions = false

  private get questionTypes () {
    return DictionaryModule.taskQuestionTypes
  }

  private get menu () {
    const list = []
    if (!this.showRelatedQuestions) {
      list.push({ icon: '$tiptapLink', iconColor: 'blue', name: 'Привязать к вопросу', value: MenuActions.ADD_RELATED_QUESTIONS })
    }
    if (!this.showAdditionalFile) {
      list.push({ icon: '$file', iconColor: 'blue', name: 'Добавить файл', value: MenuActions.ADD_FILE })
    }
    if (!this.showAdditionalHintQuestion) {
      list.push({ icon: '$question', iconColor: 'blue', name: 'Подсказка', value: MenuActions.ADD_HINT_QUESTION })
    }
    if (!this.showAdditionalHintAnswer) {
      list.push({ icon: '$smile', iconColor: 'blue', name: 'Пояснение ответа ', value: MenuActions.ADD_HINT_ANSWER })
    }
    return list
  }

  private get actions() {
    return [
      { icon: '$plus', iconColor: 'teal', name: 'Добавить новый', value: MenuTestActions.ADD },
      { disabled: this.index === 0, icon: '$moveUp', iconColor: 'teal', name: 'Переместить вверх', value: MenuTestActions.UP },
      { disabled: this.isLast, icon: '$moveDown', iconColor: 'teal', name: 'Переместить вниз', value: MenuTestActions.DOWN },
      { icon: '$duplicate', iconColor: 'teal', name: 'Копировать', value: MenuTestActions.COPY },
      { icon: '$trash', iconColor: 'teal', name: 'Удалить вопрос', value: MenuTestActions.DELETE },
    ]
  }

  private get isQuestionText () {
    return this.form.type === TaskQuestionType.TEXT
  }

  private get isQuestionSingle () {
    return this.form.type === TaskQuestionType.SINGLE
  }

  private get isQuestionSequence () {
    return this.form.type === TaskQuestionType.SEQUENCE
  }

  private get isQuestionRatio () {
    return this.form.type === TaskQuestionType.RATIO
  }

  private get isQuestionMultiple () {
    return this.form.type === TaskQuestionType.MULTIPLE
  }

  private get isQuestionNoAnswer() {
    return this.form.type === TaskQuestionType.NO_ANSWER
  }

  private get rateTypes () {
    const rateTypes = DictionaryModule.taskRateTypes

    if (this.isQuestionMultiple || this.isQuestionRatio) {
      return rateTypes.filter((rateType: NameValueResource) => [
        TaskRateType.EVERY_RIGHT,
        TaskRateType.ONE_ERROR,
        TaskRateType.MANY_ERRORS,
        TaskRateType.NO_ERRORS,
      ].includes(rateType.value as TaskRateType))
    } else if (this.isQuestionSequence || this.isQuestionText) {
      return rateTypes.filter((rateType: NameValueResource) => [
        TaskRateType.NO_RIGHT,
        TaskRateType.NO_ERRORS,
      ].includes(rateType.value as TaskRateType))
    } else if (this.isQuestionSingle) {
      return rateTypes.filter((rateType: NameValueResource) => [
        TaskRateType.NO_ERRORS,
      ].includes(rateType.value as TaskRateType))
    }

    return []
  }

  private buttonList = ['bold', 'italic', 'underline', 'strike', 'subscript', 'superscript', 'color', 'marker', 'image']

  private mounted () {
    this.showAdditionalFile = this.form.mediaIds.length > 0
    this.showAdditionalHintAnswer = !!this.form.hintAnswer
    this.showAdditionalHintQuestion = !!this.form.hintQuestion
    this.showRelatedQuestions = !!this.form.relatedUuid
  }

  private handleLoad () {
    (this.$refs.formWrapper as ExerciseTaskQuestionWrapper).fixCardHeight()
  }

  private handleAddForm() {
    this.$emit('add-form', this.index + 1, GET_DEFAULT_TEST_QUESTION_FORM())
  }

  private handleCopyForm() {
    this.$emit('add-form', this.index + 1, {
      ...omit(this.form, 'id'),
      answers: (this.form as TestQuestionRequest).answers.map((answer: TestAnswerRequest) => omit(answer, 'id')),
      sequences: (this.form as TestQuestionRequest).sequences.map((sequence: TestSequenceRequest) => omit(sequence, 'id')),
      uuid: uuid(),
    })
  }

  private handleChangeQuestionType () {
    this.form.rateType = this.rateTypes.length ? this.rateTypes[0].value as TaskRateType : TaskRateType.NO_RIGHT
    this.form.answers = [GET_DEFAULT_TEST_ANSWER()]
    this.form.sequences = []
  }

  private handleMenuItemClick (action: MenuActions) {
    switch(action) {
    case MenuActions.ADD_FILE:
      this.showAdditionalFile = true
      break
    case MenuActions.ADD_HINT_ANSWER:
      this.showAdditionalHintAnswer = true
      break
    case MenuActions.ADD_HINT_QUESTION:
      this.showAdditionalHintQuestion = true
      break
    case MenuActions.ADD_RELATED_QUESTIONS:
      this.showRelatedQuestions = true
      break
    }
  }

  private handleMenuItemActionClick(action: MenuTestActions) {
    switch(action) {
    case MenuTestActions.ADD:
      this.handleAddForm()
      break
    case MenuTestActions.COPY:
      this.handleCopyForm()
      break
    case MenuTestActions.DELETE:
      this.$emit('delete-form', this.index)
      break
    case MenuTestActions.DOWN:
      this.$emit('moveDown', this.index)
      break
    case MenuTestActions.UP:
      this.$emit('moveUp', this.index)
      break
    }
  }
}
