

















































import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import Confirmation from '@/components/modals/Confirmation.vue'
import ExerciseForm from '@/components/forms/exercise/ExerciseForm.vue'
import ExerciseTaskForm from '@/components/forms/exercise/ExerciseTaskForm.vue'
import LinkTabs from '@/components/_uikit/LinkTabs.vue'
import Parameters from '@/components/_uikit/Parameters.vue'
import ManagerExercisesModule from '@/store/modules/manager/exercises'
import { ExerciseStore } from '@/store/types'
import ExerciseEditMixin from '@/mixins/manager/ExerciseEditMixin'
import { convertDateToMSK, convertHoursMinutesToSeconds } from '@/utils/functions'
import MentorExercisesModule from '@/store/modules/mentor/exercises'
import ManagerCoursesModule from '@/store/modules/manager/courses'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    Confirmation,
    ExerciseForm,
    ExerciseTaskForm,
    LinkTabs,
    Parameters,
  },
})
export default class BankEdit extends Mixins(ExerciseEditMixin) {
  private get breadcrumbs () {
    return [
      { name: 'Банк домашних заданий', path: '/manager/bank/exercises' },
    ]
  }

  private get exerciseUUID(): string {
    return this.$route.params.exerciseUUID
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private autoCheck = false

  private isLoading = true

  private mounted () {
    this.$bus.$on('changeAutoCheck', this.handleAutoCheck)
    this.$bus.$on('addMaterials', this.addMaterials)
    this.fetchExercise()
  }

  private destroyed() {
    this.$bus.$off('changeAutoCheck', this.handleAutoCheck as any)
    this.$bus.$off('addMaterials', this.addMaterials as any)
  }

  private handleAutoCheck(value: boolean) {
    this.autoCheck = value
  }

  private fetchExercise () {
    ManagerCoursesModule.setExercise(null)
    MentorExercisesModule.setExercise(null)
    if (!this.exercise || this.exercise.uuid !== this.exerciseUUID) {
      this.isLoading = true
      ManagerExercisesModule.fetchExercise(this.$route.params.exerciseUUID)
        .then(() => {
          if (!this.exercise?.tasks.length) {
            this.handleAddTask('exercise')
          } else {
            this.syncTasks('exercise')
          }
          this.autoCheck = this.exercise ? this.exercise.autoCheck : false
        })
        .catch((error: any) => {
          this.notifyError(error)
          this.$router.push({ name: 'manager.bank.exercises' })
        })
        .finally(() => this.isLoading = false)
    } else {
      this.isLoading = false
      if (!this.exercise.tasks.length) {
        this.handleAddTask('exercise')
      } else {
        this.syncTasks('exercise')
      }
      this.autoCheck = this.exercise.autoCheck
    }
  }

  // Сохранение контейнера дз
  private handleExerciseSubmit(form: ExerciseStore) {
    if (this.exercise) {
      const formSubmit = {
        ...form,
        avgExecutionSeconds: convertHoursMinutesToSeconds(form.avgExecutionSeconds as unknown as string),
        deadlineAt: form.deadlineAt ? convertDateToMSK(form.deadlineAt, this.isLocalTimezone) : form.deadlineAt,
        spendingAt: form.spendingAt ? convertDateToMSK(form.spendingAt, this.isLocalTimezone) : form.spendingAt,
      }

      ManagerExercisesModule.saveExercise({
        exerciseUUID: this.exercise.uuid,
        params: formSubmit,
      })
        .then(response => {
          if (response.tasks.length) {
            this.syncTasks('exercise')
          }
          this.notifySuccess('Основа задания сохранена')
        })
        .catch(this.notifyError)
    }
  }

  private handleExerciseDelete () {
    if (this.exercise) {
      ManagerExercisesModule.deleteExercise(this.exercise.uuid)
        .then(() => {
          this.notifySuccess('Задание удалено')
          this.$router.push({ name: 'manager.bank.exercises' })
        })
        .catch(this.notifyError)
    }
  }

  private confirmDeleteExercise () {
    this.confirm.open(
      'Удаление задания',
      'Вы действительно хотите полностью удалить выбранное задание?',
      {
        buttonConfirmText: 'Удалить',
      },
    )
      .then(this.handleExerciseDelete)
      .catch(() => {return})
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Редактирование задания',
    }
  }
}
