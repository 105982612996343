








































































































import Draggable from 'vuedraggable'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

import TextInput from '@/components/_uikit/controls/TextInput.vue'
import { GET_DEFAULT_TEST_ANSWER } from '@/components/forms/exercise/constants'
import { alphabet, alphabetEng } from '@/utils/constants'
import TestQuestionMixin from '@/mixins/manager/TestQuestionMixin'
import { NameValueResource, TestSequenceRequest } from '@/store/types'
import TextAreaInput from '@/components/_uikit/controls/TextAreaInput.vue'

@Component({
  components: {
    Draggable,
    TextAreaInput,
    TextInput,
    ValidationProvider,
  },
})
export default class QuestionRatioForm extends Mixins(TestQuestionMixin) {
  // Дата выдачи д/з наступила?
  @Prop({ default: false })
  private isSpendingAt!: boolean

  @Prop({ required: true })
  private subject!: NameValueResource

  private alphabet = this.subject.value === 9 ? alphabetEng : alphabet
  private state = 'column'

  protected handleSequencesDrag () {
    this.form.sequences.forEach((sequence: TestSequenceRequest, index) => {
      sequence.position = index + 1
    })
  }

  private handleAddSequence() {
    this.form.sequences.push({
      ...GET_DEFAULT_TEST_ANSWER(),
      position: this.form.sequences.length + 1,
    })

    setTimeout(() => {
      const { sequences } = this.$refs as any
      sequences[sequences.length - 1].$el.querySelector('textarea').focus()
    }, 100)
  }

  private mounted() {
    this.state = this.form.displayType || 'column'
    this.$bus.$on('handleChangeSubmit', this.handleChangeSubmit)
  }

  private destroyed() {
    this.$bus.$off('handleChangeSubmit', this.handleChangeSubmit as any)
  }

  private handleChangeSubmit(id: number) {
    this.alphabet = id === 9 ? alphabetEng : alphabet
  }

  private handleChangeDisplayType(view: string) {
    this.state = view
    this.form.displayType = view
  }
}
