





































import Draggable from 'vuedraggable'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

import TextInput from '@/components/_uikit/controls/TextInput.vue'
import TestQuestionMixin from '@/mixins/manager/TestQuestionMixin'
import { TestAnswerRequest } from '@/store/types'

@Component({
  components: {
    Draggable,
    TextInput,
    ValidationProvider,
  },
})
export default class QuestionSingleForm extends Mixins(TestQuestionMixin) {
  // Дата выдачи д/з наступила?
  @Prop({ default: false })
  private isSpendingAt!: boolean

  private rightIndex = 0

  private mounted () {
    const correctIndex = this.form.answers.findIndex((answer: TestAnswerRequest) => answer.isCorrect)
    if (correctIndex) {
      this.rightIndex = correctIndex
    } else {
      this.form.answers[0].isCorrect = true
    }
  }

  private handleChangeRightAnswer (index: number) {
    this.form.answers.forEach((answer: TestAnswerRequest) => { answer.isCorrect = false })
    this.form.answers[index].isCorrect = true
  }
}
