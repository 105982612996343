






































import { Component, Mixins, Prop } from 'vue-property-decorator'
import Draggable from 'vuedraggable'

// components
import Player from '@/components/_uikit/Player.vue'
import UploadInput from '@/components/_uikit/controls/UploadInput.vue'
// mixins
import SpeakingQuestionMixin from '@/mixins/manager/SpeakingQuestionMixin'
// store
import { MediaResource, MediaResponse } from '@/store/types'
import { GET_DEFAULT_SPEAKING_SUB_QUESTION } from '@/components/forms/exercise/constants'

@Component({
  components: {
    Draggable,
    Player,
    UploadInput,
  },
})
export default class QuestionAudioQueueForm extends Mixins(SpeakingQuestionMixin) {
  // Дата выдачи д/з наступила?
  @Prop({ default: false })
  private isSpendingAt!: boolean

  @Prop({ default: () => ([]) })
  private records!: MediaResource[]

  private get uploadAudioParams() {
    return [{
      name: 'type',
      value: 'audio',
    }]
  }

  private visibleUploadAudio = false

  private uploadAudio() {
    (this.$refs.upload as UploadInput).openPicker()
    this.visibleUploadAudio = true
  }

  private handleUploadAudio(response: MediaResponse) {
    this.records.push(response)
    this.visibleUploadAudio = false
    this.addSubQuestion(response.id)
  }

  private addSubQuestion(mediaId: number) {
    this.form.questions.push({
      ...GET_DEFAULT_SPEAKING_SUB_QUESTION(),
      mediaId,
      position: this.form.questions.length + 1,
    })
  }
}
