import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { SpeakingSubQuestionRequest, SpeakingQuestionRequest } from '@/store/types'
import { GET_DEFAULT_SPEAKING_SUB_QUESTION } from '@/components/forms/exercise/constants'

@Component
export default class SpeakingQuestionMixin extends Vue {
  @Prop({ required: true })
  protected form!: SpeakingQuestionRequest

  @Prop({ required: true })
  protected formIndex!: number

  protected handleSubQuestionsDrag() {
    this.form.questions.forEach((answer: SpeakingSubQuestionRequest, index: number) => {
      answer.position = index + 1
    })
  }

  protected handleAddSubQuestion() {
    this.form.questions.push({
      ...GET_DEFAULT_SPEAKING_SUB_QUESTION(),
      position: this.form.questions.length + 1,
    })

    setTimeout(() => {
      const { subQuestion } = this.$refs as any
      const lastSubQuestionInput = subQuestion ? subQuestion[subQuestion.length - 1].$el.querySelector('input') : null

      if (lastSubQuestionInput) {
        lastSubQuestionInput.focus()
      }
    }, 100)
  }
}
